import React from 'react'
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';



export default function TwoColStackingAnimation({blok}) {
  return (
    <div className="two-col-stacking-animation relative space-y-32">
      {blok.items.map((item,i) => (
        <Slide key={i} blok={item} i={i} />
      ))}
    </div>
  )
}


const Slide = ({ blok, i }) => {
  return (
    <div className={`sticky bg-dark-blue top-20 md:top-32`} >
      <div className="relative" {...storyblokEditable(blok)}>
        <StoryblokComponent blok={blok} key={blok._uid} />
      </div>
    </div>
  )

}